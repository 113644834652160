const STRUCT = {
  ID: "id",
  NAME: "name",
  EMAIL: "email",
  PIN: "pin",
  DIRECTION_ID: "direction_id",
  DIRECTION: "direction",
  LINE_ID: "line_id",
  LINE: "line",
  IS_DELETE_AVAILABLE: "is_delete_available",
  MANAGER_NAME: "manager_name",
  MANAGER_EMAIL: "manager_email",
  POSITION: "position",
};

export default STRUCT;
