import React, { FC, useCallback, useMemo, useState } from "react";
import { branch } from "baobab-react/higher-order";

import USER_STRUCT from "../../../../store/struct/entities/user";
import VOCABULARY_STRUCT, {
  NA_CONST,
} from "../../../../store/struct/entities/vocabulary";
import {
  agentsSelector,
  directionsSelector,
  linesSelector,
} from "../../../../store/struct/selectors";

import Select from "../../../../components/form/select";
import Checkbox from "../../../../components/checkbox";

import styles from "./index.module.scss";
import { getUniqueParam } from "../../../../utils";

interface TestUsersProps {
  agents: any;
  selectedUsers: number[];
  currentLine: string | null;
  currentDirection: string | null;
  settingsIsDisabled: boolean;
  directions: any;
  lines: any;
  toggleSelectedUser: (userId: number) => void;
  toggleSelectAllUsers: (users: any[]) => void;
}

const TestUsers: FC<TestUsersProps> = ({
  agents,
  selectedUsers,
  currentLine,
  currentDirection,
  settingsIsDisabled,
  directions,
  lines,
  toggleSelectedUser,
  toggleSelectAllUsers,
}) => {
  const [currentName, setCurrentName] = useState<string | null>(null);
  const [currentManager, setCurrentManager] = useState<string | null>(null);

  const handleChangeNameFilter = useCallback((name: string | null) => {
    setCurrentName(name);
  }, []);
  const handleChangeManagerFilter = useCallback((manager: string | null) => {
    setCurrentManager(manager);
  }, []);

  const namesOptions = useMemo(() => {
    return getUniqueParam(agents, USER_STRUCT.NAME, USER_STRUCT.NAME);
  }, [agents]);
  const managersOptions = useMemo(() => {
    return getUniqueParam(
      agents,
      USER_STRUCT.MANAGER_NAME,
      USER_STRUCT.MANAGER_NAME
    );
  }, [agents]);

  const isUserSelected = (id: number) => {
    return selectedUsers.findIndex((userId) => userId === id) > -1;
  };

  const filteredUsers = useMemo(() => {
    return agents
      .filter((user: any) => {
        if (currentLine) {
          if (currentLine === NA_CONST) {
            return true;
          } else {
            return (
              user[USER_STRUCT.LINE_ID] === currentLine ||
              user[USER_STRUCT.LINE] === NA_CONST
            );
          }
        } else {
          return true;
        }
      })
      .filter((user: any) => {
        if (currentDirection) {
          if (currentDirection === NA_CONST) {
            return true;
          } else {
            return (
              user[USER_STRUCT.DIRECTION_ID] === currentDirection ||
              user[USER_STRUCT.DIRECTION] === NA_CONST
            );
          }
        } else {
          return true;
        }
      })
      .filter((user: any) => {
        if (currentName) {
          return user[USER_STRUCT.NAME] === currentName;
        } else {
          return true;
        }
      })
      .filter((user: any) => {
        if (currentManager) {
          return (
            user[USER_STRUCT.MANAGER_NAME] === currentManager ||
            !user[USER_STRUCT.MANAGER_NAME]
          );
        } else {
          return true;
        }
      });
  }, [agents, currentLine, currentDirection, currentName, currentManager]);

  const currentLineName = currentLine
    ? currentLine === NA_CONST
      ? currentLine
      : lines.find(
          (currLine: any) =>
            currLine[VOCABULARY_STRUCT.ID] === Number(currentLine)
        )[VOCABULARY_STRUCT.TITLE]
    : "Линия";
  const currentDirectionName = currentDirection
    ? currentDirection === NA_CONST
      ? currentDirection
      : directions.find(
          (currDirection: any) =>
            currDirection[VOCABULARY_STRUCT.ID] === Number(currentDirection)
        )[VOCABULARY_STRUCT.TITLE]
    : "Направление";

  const isSelectAllActive = useMemo(() => {
    return filteredUsers.every((filteredUser: any) =>
      selectedUsers.some(
        (selectedUserId: number) => filteredUser.id === selectedUserId
      )
    );
  }, [filteredUsers, selectedUsers]);

  return (
    <>
      <div
        className={styles.titleText}
      >{`Выберите пользоватей для теста из списка, отфильтрованного на форме "Выбор вопросов"`}</div>
      <div className={styles.titleContainer}>
        <div className={styles.titleText}>{`Выбрано пользователей:`}</div>
        <div className={styles.usersCountText}>{`${selectedUsers.length}`}</div>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.titleText}>Фильтры:</div>
        <div className={styles.filter}>{currentLineName}</div>
        <div className={styles.filter}>{currentDirectionName}</div>
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeNameFilter}
          options={namesOptions}
          value={currentName}
          className={styles.selectMargin}
          placeholder="ФИО"
          selectablePlaceholder
          onReset={() => handleChangeNameFilter(null)}
        />
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeManagerFilter}
          options={managersOptions}
          value={currentManager}
          className={styles.selectMargin}
          placeholder="Менеджер"
          selectablePlaceholder
          onReset={() => handleChangeManagerFilter(null)}
        />
      </div>
      {filteredUsers.length > 0 ? (
        <>
          <div className={styles.userContainer}>
            <Checkbox
              id={`user-select_all`}
              onChange={() => {
                toggleSelectAllUsers(filteredUsers);
              }}
              checked={isSelectAllActive}
              className={styles.checkbox}
              disabled={settingsIsDisabled}
            />
            <div
              className={`${
                settingsIsDisabled
                  ? styles.userTextContainerDisabled
                  : styles.userTextContainer
              }`}
              onClick={() => {
                if (!settingsIsDisabled) {
                  toggleSelectAllUsers(filteredUsers);
                }
              }}
            >
              {"Выбрать все"}
            </div>
          </div>
          {filteredUsers.map((user: any) => (
            <div
              key={`user-${user[USER_STRUCT.ID]}`}
              className={styles.userContainer}
            >
              <Checkbox
                id={`user-${user[USER_STRUCT.ID]}`}
                onChange={() => {
                  toggleSelectedUser(user[USER_STRUCT.ID]);
                }}
                checked={isUserSelected(user[USER_STRUCT.ID])}
                className={styles.checkbox}
                disabled={settingsIsDisabled}
              />
              <div
                className={`${
                  settingsIsDisabled
                    ? styles.userTextContainerDisabled
                    : styles.userTextContainer
                }`}
                onClick={() => {
                  if (!settingsIsDisabled) {
                    toggleSelectedUser(user[USER_STRUCT.ID]);
                  }
                }}
              >{`${user[USER_STRUCT.NAME]}`}</div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.emptyText}>
          {"Нет пользователей, соответствующих выбранным фильтрам"}
        </div>
      )}
    </>
  );
};

export default branch(
  {
    agents: agentsSelector(),
    directions: directionsSelector(),
    lines: linesSelector(),
  },
  TestUsers
);
