import React, { FC, createRef, useCallback, useMemo, useState } from "react";
import { branch } from "baobab-react/higher-order";

import VOCABULARY_STRUCT from "../../../store/struct/entities/vocabulary";
import ENTITIES_STRUCT from "../../../store/struct/entities";
import {
  removeQuestion,
  uploadQuestionsFile,
  editQuestion,
  createQuestion,
  createCategory,
  createDirection,
  createLine,
} from "../../../store/struct/entities/admin/actions";
import {
  questionsSelector,
  linesSelector,
  directionsSelector,
  categoriesSelector,
} from "../../../store/struct/selectors";
import QUESTION_STRUCT, {
  QUESTION_TYPES_ID,
  QUESTION_TYPES_NAMES,
} from "../../../store/struct/entities/question";

import Table, { TYPES as TABLE_TYPES } from "../../../components/table";
import Form, { TYPES as FORM_TYPES } from "../../../components/form";
import Button, { THEMES } from "../../../components/button";
import { SUBTYPES } from "../../../components/form/input";
import Dialog from "../../../components/dialog";

import styles from "../index.module.scss";
import Dropzone from "../../../components/dropzone";
import { getUTCDate } from "../../../utils/time";

const HEADERS = [
  "№",
  "Вопрос",
  "Добавлен",
  "Категория",
  "Направление",
  "Линия",
  "Комментарий",
  "",
];

const PARAMETER_TO_ADD = {
  [ENTITIES_STRUCT.CATEGORIES]: {
    FORM_TITLE: "категории",
    INPUT_TITLE: "Категория:",
    INPUT_PLACEHOLDER: "Введите название категории",
  },
  [ENTITIES_STRUCT.DIRECTIONS]: {
    FORM_TITLE: "направления",
    INPUT_TITLE: "Направление:",
    INPUT_PLACEHOLDER: "Введите название направления",
  },
  [ENTITIES_STRUCT.LINES]: {
    FORM_TITLE: "линии",
    INPUT_TITLE: "Линия:",
    INPUT_PLACEHOLDER: "Введите название линии",
  },
};

interface QuestionsProps {
  questions: any;
  dispatch: any;
  lines: any;
  directions: any;
  categories: any;
}

const Questions: FC<QuestionsProps> = ({
  questions,
  dispatch,
  lines,
  directions,
  categories,
}) => {
  const [showLoadDialog, setShowLoadDialog] = useState<any>(false);
  const [formData, setFormData] = useState<any>(null);
  const [showEditDialog, setShowEditDialog] = useState<any>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<any>(false);
  const [showAddToDictionaryDialog, setShowAddToDictionaryDialog] =
    useState<boolean>(false);
  const [dictionaryParameterToAdd, setDictionaryParameterToAdd] =
    useState<any>(null);
  const [isDictionarySubmitDisabled, setIsDictionarySubmitDisabled] =
    useState<any>(true);
  const [isDictionaryParameterError, setIsDictionaryParameterError] =
    useState<any>(false);

  const [isQuestionError, setIsQuestionError] = useState<any>(false);
  const [isAswersError, setIsAswersError] = useState<any>(false);
  const [isCorrectAnswersError, setIsCorrectAnswersError] =
    useState<any>(false);
  const [isElementsError, setIsElementsError] = useState<any>(false);
  const [elementsIsDisabled, setElementsIsDisabled] = useState<boolean>(false);
  const [questionToEdit, setQuestionToEdit] = useState<any>(null);

  const dropzone = createRef<any>();
  const form = createRef<any>();
  const dictionaryForm = createRef<any>();

  const questionsToShow = questions.filter(
    (question: any) => !question[QUESTION_STRUCT.IS_HIDED]
  );

  const deleteQuestion = (data: any) => {
    if (data[QUESTION_STRUCT.IS_DELETE_AVAILABLE]) {
      dispatch(removeQuestion, {
        [QUESTION_STRUCT.ID]: data[QUESTION_STRUCT.ID],
      });
    } else {
      dispatch(editQuestion, {
        [QUESTION_STRUCT.ID]: data[QUESTION_STRUCT.ID],
        [QUESTION_STRUCT.IS_HIDED]: true,
      });
    }
  };

  const openEditQuestion = (data: any) => {
    setQuestionToEdit(data);
    setFormData(data);
    setShowEditDialog(true);
  };

  const closeDialog = () => {
    setQuestionToEdit(null);
    setShowEditDialog(false);
  };

  const closeAddDictionaryParameterDialog = () => {
    setDictionaryParameterToAdd(null);
    setShowAddToDictionaryDialog(false);
    setIsDictionarySubmitDisabled(true);
    setIsDictionaryParameterError(false);
  };

  const saveQuestion = () => {
    const data = questionToEdit
      ? {
          id: formData[QUESTION_STRUCT.ID],
          ...form.current.getState(),
        }
      : {
          ...form.current.getState(),
        };

    if (data[QUESTION_STRUCT.TYPE_ID] !== QUESTION_TYPES_ID.MATCHING) {
      data[QUESTION_STRUCT.ELEMENTS] = null;
    }

    if (questionToEdit) {
      dispatch(editQuestion, data);
    } else {
      dispatch(createQuestion, data);
    }
    closeDialog();
  };

  const saveDictionary = () => {
    const data = {
      ...dictionaryForm.current.getState(),
    };

    switch (dictionaryParameterToAdd) {
      case ENTITIES_STRUCT.CATEGORIES:
        dispatch(createCategory, data);

        break;
      case ENTITIES_STRUCT.DIRECTIONS:
        dispatch(createDirection, data);

        break;
      case ENTITIES_STRUCT.LINES:
        dispatch(createLine, data);

        break;
    }
    closeAddDictionaryParameterDialog();
  };

  const getTypesOptions = () => {
    return Object.values(QUESTION_TYPES_ID).map((type: number) => {
      return {
        id: type,
        title: Object.values(QUESTION_TYPES_NAMES)[type - 1],
      };
    });
  };

  const handleChangeDictionaryForm = (data: any) => {
    setIsDictionarySubmitDisabled(
      !data[VOCABULARY_STRUCT.TITLE] || isDictionaryParameterError
    );
  };

  const handleChangeForm = (data: any) => {
    setElementsIsDisabled(
      data[QUESTION_STRUCT.TYPE_ID] !== QUESTION_TYPES_ID.MATCHING
    );

    try {
      const parsedCorrectAnswers = JSON.parse(
        data[QUESTION_STRUCT.CORRECT_ANSWERS]
      );
      const parsedAnswers = JSON.parse(data[QUESTION_STRUCT.ANSWERS]);
      setIsAswersError(
        data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MANY_OF_MANY &&
          parsedAnswers.length === 1
      );
      setIsCorrectAnswersError(
        (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.ONE_OF_MANY &&
          parsedCorrectAnswers.length > 1) ||
          (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MANY_OF_MANY &&
            parsedCorrectAnswers.length === 1)
      );
      if (data[QUESTION_STRUCT.TYPE_ID] !== QUESTION_TYPES_ID.MATCHING) {
        setIsElementsError(false);
      }

      setIsSubmitDisabled(
        !data[QUESTION_STRUCT.TITLE] ||
          !data[QUESTION_STRUCT.TYPE_ID] ||
          !data[QUESTION_STRUCT.ANSWERS] ||
          !data[QUESTION_STRUCT.CORRECT_ANSWERS] ||
          isQuestionError ||
          isAswersError ||
          isCorrectAnswersError ||
          isElementsError ||
          (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MATCHING &&
            !data[QUESTION_STRUCT.ELEMENTS])
      );
      return;
    } catch (e) {
      setIsSubmitDisabled(
        !data[QUESTION_STRUCT.TITLE] ||
          !data[QUESTION_STRUCT.TYPE_ID] ||
          !data[QUESTION_STRUCT.ANSWERS] ||
          !data[QUESTION_STRUCT.CORRECT_ANSWERS] ||
          isQuestionError ||
          isAswersError ||
          isCorrectAnswersError ||
          isElementsError ||
          (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MATCHING &&
            !data[QUESTION_STRUCT.ELEMENTS])
      );
      return;
    }
  };

  const validateAnswersInput = (input: string): boolean => {
    // Регулярное выражение для валидации массива строк с минимум одним элементом
    const regex = /^\s*\[\s*"[^"]+"\s*(,\s*"[^"]*"\s*)*\]\s*$/;

    // Проверяем соответствие строки регулярному выражению
    if (!regex.test(input)) {
      return false;
    }

    try {
      // Пробуем преобразовать строку в массив
      const parsedArray = JSON.parse(input);

      // Проверяем, что это массив строк с минимум одним элементом
      if (
        !Array.isArray(parsedArray) ||
        parsedArray.length === 0 ||
        !parsedArray.every((item) => typeof item === "string")
      ) {
        return false;
      }

      return true;
    } catch (e) {
      // Если произошла ошибка при парсинге, возвращаем false
      return false;
    }
  };

  const validateQuestion = useCallback((data: any) => {
    setIsQuestionError(data.length > 0 ? false : true);
  }, []);
  const validateAnswers = useCallback((data: any) => {
    setIsAswersError(data.length > 0 ? !validateAnswersInput(data) : true);
  }, []);
  const validateCorrectAnswers = useCallback((data: any) => {
    setIsCorrectAnswersError(
      data.length > 0 ? !validateAnswersInput(data) : true
    );
  }, []);
  const validateElements = useCallback((data: any) => {
    setIsElementsError(data.length > 0 ? !validateAnswersInput(data) : true);
  }, []);
  const validateDictionaryParameter = useCallback(
    (data: any) => {
      const isInputNotEmpty = data.length > 0 ? true : false;

      if (isInputNotEmpty) {
        switch (dictionaryParameterToAdd) {
          case ENTITIES_STRUCT.CATEGORIES:
            const currCategoryIdx = categories.findIndex(
              (category: any) =>
                category[VOCABULARY_STRUCT.TITLE] === data.trim()
            );
            setIsDictionaryParameterError(currCategoryIdx > -1);
            break;
          case ENTITIES_STRUCT.DIRECTIONS:
            const currDirectionIdx = directions.findIndex(
              (direction: any) =>
                direction[VOCABULARY_STRUCT.TITLE] === data.trim()
            );
            setIsDictionaryParameterError(currDirectionIdx > -1);
            break;
          case ENTITIES_STRUCT.LINES:
            const currLineIdx = lines.findIndex(
              (line: any) => line[VOCABULARY_STRUCT.TITLE] === data.trim()
            );
            setIsDictionaryParameterError(currLineIdx > -1);
            break;
        }
      }
    },
    [categories, dictionaryParameterToAdd, directions, lines]
  );

  const dictionaryFormConfig = useMemo(() => {
    const config = dictionaryParameterToAdd
      ? [
          {
            id: VOCABULARY_STRUCT.TITLE,
            title: PARAMETER_TO_ADD[dictionaryParameterToAdd].INPUT_TITLE,
            type: FORM_TYPES.INPUT,
            subtype: SUBTYPES.TEXTAREA,
            initialValue: "",
            attrs: {
              rows: 3,
              placeholder:
                PARAMETER_TO_ADD[dictionaryParameterToAdd].INPUT_PLACEHOLDER,
            },
            titleUnder: isDictionaryParameterError
              ? "Введенный параметр уже существует"
              : "",

            classNameLabelUnder: `${
              isDictionaryParameterError ? styles.error : ""
            }`,
            className: `${
              isDictionaryParameterError ? styles.errorInputContainer : ""
            }`,
            classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
              isDictionaryParameterError ? styles.error : ""
            }`,
            classNameLi: styles.rowContainer,
            validationFunc: validateDictionaryParameter,
          },
        ]
      : [];
    return config;
  }, [
    dictionaryParameterToAdd,
    isDictionaryParameterError,
    validateDictionaryParameter,
  ]);

  const formConfig = useMemo(() => {
    const config = [
      {
        id: QUESTION_STRUCT.TITLE,
        title: "Вопрос:",
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.TITLE]
          : "",
        attrs: {
          rows: 3,
          placeholder: "Введите вопрос",
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          isQuestionError ? styles.error : ""
        }`,
        className: `${isAswersError ? styles.errorInputContainer : ""}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
          isQuestionError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateQuestion,
      },
      {
        id: QUESTION_STRUCT.TYPE_ID,
        title: "Тип:",
        type: FORM_TYPES.SELECT,
        options: getTypesOptions(),
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.TYPE_ID]
          : null,
        placeholder: "Выберите тип",
      },
      {
        id: QUESTION_STRUCT.LINE_ID,
        title: "Линия:",
        type: FORM_TYPES.SELECT,
        options: lines,
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.LINE_ID]
          : null,
        placeholder: "Выберите линию",
        selectablePlaceholder: true,
      },
      {
        id: QUESTION_STRUCT.CATEGORY_ID,
        title: "Категория:",
        type: FORM_TYPES.SELECT,
        options: categories,
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.CATEGORY_ID]
          : null,
        placeholder: "Выберите категорию",
        selectablePlaceholder: true,
      },
      {
        id: QUESTION_STRUCT.DIRECTION_ID,
        title: "Направление:",
        type: FORM_TYPES.SELECT,
        options: directions,
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.DIRECTION_ID]
          : null,
        placeholder: "Выберите направление",
        selectablePlaceholder: true,
      },
      {
        id: QUESTION_STRUCT.ANSWERS,
        title: "Ответы:",
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.ANSWERS]
          : "",
        attrs: {
          rows: 6,
          placeholder: 'Введите ответы в формате: ["Ответ 1", "Ответ 2"]',
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          isAswersError ? styles.error : ""
        }`,
        className: `${isAswersError ? styles.errorInputContainer : ""}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
          isAswersError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateAnswers,
      },
      {
        id: QUESTION_STRUCT.CORRECT_ANSWERS,
        title: "Правильные ответы:",
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,

        initialValue: questionToEdit
          ? questionToEdit[QUESTION_STRUCT.CORRECT_ANSWERS]
          : "",
        attrs: {
          rows: 6,
          placeholder:
            'Введите правильные ответы в формате: ["Ответ 1", "Ответ 2"]',
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          isCorrectAnswersError ? styles.error : ""
        }`,
        className: `${isCorrectAnswersError ? styles.errorInputContainer : ""}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
          isCorrectAnswersError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateCorrectAnswers,
      },
      {
        id: QUESTION_STRUCT.ELEMENTS,
        title: "Элементы:",
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue:
          (questionToEdit && questionToEdit[QUESTION_STRUCT.ELEMENTS]) ?? "",
        attrs: {
          rows: 6,
          placeholder: 'Введите элементы в формате: ["Элемент 1", "Элемент 2"]',
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          isElementsError ? styles.error : ""
        }`,
        className: `${isElementsError ? styles.errorInputContainer : ""}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
          isElementsError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateElements,
        disabled: elementsIsDisabled,
      },
      {
        id: QUESTION_STRUCT.COMMENT,
        title: "Комментарий:",
        type: FORM_TYPES.INPUT,
        subtype: SUBTYPES.TEXTAREA,
        initialValue:
          (questionToEdit && questionToEdit[QUESTION_STRUCT.COMMENT]) ?? "",
        attrs: {
          rows: 3,
          placeholder: "Введите комментарий",
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
      },
    ];
    return config;
  }, [
    questionToEdit,
    isQuestionError,
    isAswersError,
    validateQuestion,
    lines,
    categories,
    directions,
    validateAnswers,
    isCorrectAnswersError,
    validateCorrectAnswers,
    isElementsError,
    validateElements,
    elementsIsDisabled,
  ]);

  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: (_: any, index: number) => index + 1,
    },
    {
      type: TABLE_TYPES.TEXT,
      className: `${styles.pressableText} ${styles.blueText} ${styles.statusText}`,
      pressable: true,
      onClick: (data: any) => openEditQuestion(data),
      getValue: (data: any) => data[QUESTION_STRUCT.TITLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const date = getUTCDate(data[QUESTION_STRUCT.CREATED_AT]);
        return `${
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        }.${
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        }.${date.getFullYear()} ${
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        }:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[QUESTION_STRUCT.CATEGORY],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[QUESTION_STRUCT.DIRECTION],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[QUESTION_STRUCT.LINE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[QUESTION_STRUCT.COMMENT],
    },
    {
      type: TABLE_TYPES.BUTTON,
      theme: THEMES.DELETE,
      onClick: deleteQuestion,
      text: "Удалить",
      min: true,
    },
  ];

  const loadQuestions = () => {
    setShowLoadDialog(true);
  };

  const addQuestion = () => {
    setShowEditDialog(true);
  };

  const onLoadQuestions = () => {
    const file = dropzone.current.getFile();

    if (file) {
      dispatch(uploadQuestionsFile, file);
      closeLoadDialog();
    }
  };
  const closeLoadDialog = () => {
    setShowLoadDialog(false);
  };

  const addDictionaryParameter = (parameter: any) => {
    setDictionaryParameterToAdd(parameter);
    setShowAddToDictionaryDialog(true);
  };

  const testsHeaderConfig = [
    {},
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {},
  ];

  return (
    <>
      {questionsToShow.length > 0 ? (
        <Table
          className={styles.tableWithControl}
          headers={HEADERS}
          config={tableConfig}
          data={questionsToShow}
          headerConfig={testsHeaderConfig}
          filters={[2, 3, 4, 5, 6]}
        />
      ) : (
        <div className={styles.emptyListTitle}>
          Список вопросов пуст. Загрузите файл
        </div>
      )}
      <div
        className={`${styles.alignRight} ${styles.block} ${styles.blockSpaceBetween}`}
      >
        <div>
          {/* @ts-ignore */}
          <Button
            onClick={() => addDictionaryParameter(ENTITIES_STRUCT.CATEGORIES)}
            theme={THEMES.NEW_PRIMARY}
            className={styles.addButton}
          >
            Добавить категорию
          </Button>
          {/* @ts-ignore */}
          <Button
            onClick={() => addDictionaryParameter(ENTITIES_STRUCT.DIRECTIONS)}
            theme={THEMES.NEW_PRIMARY}
            className={styles.addButton}
          >
            Добавить направление
          </Button>
          {/* @ts-ignore */}
          <Button
            onClick={() => addDictionaryParameter(ENTITIES_STRUCT.LINES)}
            theme={THEMES.NEW_PRIMARY}
          >
            Добавить линию
          </Button>
        </div>
        <div>
          {/* @ts-ignore */}
          <Button
            onClick={addQuestion}
            theme={THEMES.NEW_PRIMARY}
            className={styles.addButton}
          >
            Добавить
          </Button>
          {/* @ts-ignore */}
          <Button onClick={loadQuestions} theme={THEMES.NEW_PRIMARY}>
            Загрузить
          </Button>
        </div>
      </div>
      {showLoadDialog && (
        // @ts-ignore
        <Dialog
          title="Загрузка файла"
          submitTitle="Сохранить"
          onSubmit={onLoadQuestions}
          cancelTitle="Отменить"
          onCancel={closeLoadDialog}
          fullScreen
        >
          <Dropzone ref={dropzone} />
        </Dialog>
      )}
      {showEditDialog && (
        // @ts-ignore
        <Dialog
          title={
            questionToEdit ? "Редактирование вопроса" : "Добавление вопроса"
          }
          submitTitle="Сохранить вопрос"
          onSubmit={saveQuestion}
          submitDisabled={isSubmitDisabled}
          cancelTitle="Отменить"
          onCancel={closeDialog}
          fullScreen
        >
          <Form
            ref={form}
            // @ts-ignore
            config={formConfig}
            onChange={handleChangeForm}
          />
        </Dialog>
      )}
      {showAddToDictionaryDialog && (
        // @ts-ignore
        <Dialog
          title={`Добавление ${PARAMETER_TO_ADD[dictionaryParameterToAdd].FORM_TITLE}`}
          submitTitle="Сохранить"
          onSubmit={saveDictionary}
          submitDisabled={isDictionarySubmitDisabled}
          cancelTitle="Отменить"
          onCancel={closeAddDictionaryParameterDialog}
          fullScreen
        >
          <Form
            ref={dictionaryForm}
            // @ts-ignore
            config={dictionaryFormConfig}
            onChange={handleChangeDictionaryForm}
          />
        </Dialog>
      )}
    </>
  );
};

export default branch(
  {
    questions: questionsSelector(),
    lines: linesSelector(),
    directions: directionsSelector(),
    categories: categoriesSelector(),
  },
  Questions
);
