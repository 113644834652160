import React, { FC, useCallback, useMemo, useState } from "react";
import { branch } from "baobab-react/higher-order";

import { questionsSelector } from "../../../../store/struct/selectors";
import QUESTION_STRUCT from "../../../../store/struct/entities/question";

import { getUTCDate } from "../../../../utils/time";
import { getUniqueParam } from "../../../../utils";

import Select from "../../../../components/form/select";
import Checkbox from "../../../../components/checkbox";

import styles from "./index.module.scss";
import { NA_CONST } from "../../../../store/struct/entities/vocabulary";

interface TestQuestionsProps {
  questions: any;
  selectedQuestions: number[];
  currentLine: string | null;
  currentDirection: string | null;
  settingsIsDisabled: boolean;
  currentQuestionDate: string | null;
  testToChangeSettings: any;
  currentQuestionComment: string | null;
  setCurrentQuestionDate: (date: string | null) => void;
  setCurrentLine: (line: string | null) => void;
  setCurrentDirection: (direction: string | null) => void;
  setCurrentQuestionComment: (comment: string | null) => void;
  toggleSelectedQuestion: (questionId: number) => void;
  resetSelectedQuestions: () => void;
  resetSelectedUsers: () => void;
  toggleSelectAllQuestions: (questions: any[]) => void;
}

const TestQuestions: FC<TestQuestionsProps> = ({
  questions,
  selectedQuestions,
  currentLine,
  currentDirection,
  settingsIsDisabled,
  currentQuestionDate,
  testToChangeSettings,
  currentQuestionComment,
  setCurrentQuestionDate,
  setCurrentLine,
  setCurrentDirection,
  setCurrentQuestionComment,
  toggleSelectedQuestion,
  toggleSelectAllQuestions,
}) => {
  const [currentCategory, setCurrentCategory] = useState<string | null>(null);
  const handleChangeLineFilter = useCallback(
    (line: string | null) => {
      setCurrentLine(line);
    },
    [setCurrentLine]
  );
  const handleChangeCategoryFilter = useCallback(
    (category: string | null) => setCurrentCategory(category),
    []
  );
  const handleChangeDirectionFilter = useCallback(
    (direction: string | null) => setCurrentDirection(direction),
    [setCurrentDirection]
  );
  const handleChangeDataFilter = useCallback(
    (date: string | null) => setCurrentQuestionDate(date),
    [setCurrentQuestionDate]
  );
  const handleChangeCommentFilter = useCallback(
    (comment: string | null) => setCurrentQuestionComment(comment),
    [setCurrentQuestionComment]
  );

  const questionsToShow =
    testToChangeSettings && settingsIsDisabled
      ? questions
      : questions.filter(
          (question: any) => !question[QUESTION_STRUCT.IS_HIDED]
        );

  const linesOptions = useMemo(() => {
    return getUniqueParam(
      questionsToShow,
      QUESTION_STRUCT.LINE_ID,
      QUESTION_STRUCT.LINE
    );
  }, [questionsToShow]);

  const categoriesOptions = useMemo(() => {
    return getUniqueParam(
      questionsToShow,
      QUESTION_STRUCT.CATEGORY_ID,
      QUESTION_STRUCT.CATEGORY
    ).filter((param: { id: number; title: string }) => {
      return param.title !== NA_CONST;
    });
  }, [questionsToShow]);

  const directionsOptions = useMemo(() => {
    return getUniqueParam(
      questionsToShow,
      QUESTION_STRUCT.DIRECTION_ID,
      QUESTION_STRUCT.DIRECTION
    );
  }, [questionsToShow]);

  const dateOptions = useMemo(() => {
    const options = questionsToShow.reduce((acc: any, currentQuestion: any) => {
      const date = getUTCDate(currentQuestion[QUESTION_STRUCT.CREATED_AT]);
      const dateTitle = `${
        date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
      }.${
        date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
      }.${date.getFullYear()} ${
        date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
      }:${
        date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
      }`;

      const currOptionIdx = acc.findIndex(
        (element: { id: string; title: string }) => element.title === dateTitle
      );
      if (currOptionIdx === -1) {
        acc.push({ id: dateTitle, title: dateTitle });
      }
      return acc;
    }, []);
    return options;
  }, [questionsToShow]);

  const commentOptions = useMemo(() => {
    const options = questionsToShow.reduce((acc: any, currentQuestion: any) => {
      const currOptionIdx = acc.findIndex(
        (element: { id: string; title: string }) =>
          element.title === currentQuestion[QUESTION_STRUCT.COMMENT]
      );
      if (currOptionIdx === -1) {
        acc.push({
          id: currentQuestion[QUESTION_STRUCT.COMMENT],
          title: currentQuestion[QUESTION_STRUCT.COMMENT],
        });
      }
      return acc;
    }, []);
    return options;
  }, [questionsToShow]);

  const isQuestionSelected = (id: number) => {
    return selectedQuestions.findIndex((questionId) => questionId === id) > -1;
  };

  const filteredQuestions = useMemo(() => {
    return questionsToShow
      .filter((question: any) => {
        if (currentLine) {
          if (currentLine === NA_CONST) {
            return question[QUESTION_STRUCT.LINE] === currentLine;
          } else {
            return question[QUESTION_STRUCT.LINE_ID] === currentLine;
          }
        } else {
          return true;
        }
      })
      .filter((question: any) => {
        if (currentCategory) {
          return (
            question[QUESTION_STRUCT.CATEGORY_ID] === currentCategory ||
            question[QUESTION_STRUCT.CATEGORY] === NA_CONST
          );
        } else {
          return true;
        }
      })
      .filter((question: any) => {
        if (currentDirection) {
          if (currentDirection === NA_CONST) {
            return question[QUESTION_STRUCT.DIRECTION] === currentDirection;
          } else {
            return question[QUESTION_STRUCT.DIRECTION_ID] === currentDirection;
          }
        } else {
          return true;
        }
      })
      .filter((question: any) => {
        if (currentQuestionDate) {
          const date = getUTCDate(question[QUESTION_STRUCT.CREATED_AT]);
          const dateTitle = `${
            date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
          }.${
            date.getMonth() < 9
              ? `0${date.getMonth() + 1}`
              : date.getMonth() + 1
          }.${date.getFullYear()} ${
            date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
          }:${
            date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
          }`;
          return dateTitle === currentQuestionDate;
        } else {
          return true;
        }
      })
      .filter((question: any) => {
        if (currentQuestionComment) {
          return question[QUESTION_STRUCT.COMMENT] === currentQuestionComment;
        } else {
          return true;
        }
      });
  }, [
    questionsToShow,
    currentLine,
    currentCategory,
    currentDirection,
    currentQuestionDate,
    currentQuestionComment,
  ]);

  const isSelectAllActive = useMemo(() => {
    return filteredQuestions.every((filteredQuestion: any) =>
      selectedQuestions.some(
        (selectedQuestionId: number) =>
          filteredQuestion.id === selectedQuestionId
      )
    );
  }, [filteredQuestions, selectedQuestions]);

  return (
    <>
      <div className={styles.titleContainer}>
        <div className={styles.titleText}>{`Выбрано вопросов:`}</div>
        <div
          className={styles.questionCountText}
        >{`${selectedQuestions.length}`}</div>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.titleText}>Фильтры:</div>
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeLineFilter}
          options={linesOptions}
          value={currentLine}
          className={styles.selectMargin}
          placeholder="Линия"
          selectablePlaceholder
          onReset={() => handleChangeLineFilter(null)}
        />
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeCategoryFilter}
          options={categoriesOptions}
          value={currentCategory}
          className={styles.selectMargin}
          placeholder="Категория"
          selectablePlaceholder
          onReset={() => handleChangeCategoryFilter(null)}
        />
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeDirectionFilter}
          options={directionsOptions}
          value={currentDirection}
          className={styles.selectMargin}
          placeholder="Направление"
          selectablePlaceholder
          onReset={() => handleChangeDirectionFilter(null)}
        />
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeDataFilter}
          options={dateOptions}
          value={currentQuestionDate}
          className={styles.selectMargin}
          placeholder="Добавлен"
          selectablePlaceholder
          onReset={() => handleChangeDataFilter(null)}
        />
        {/* @ts-ignore */}
        <Select
          onChange={handleChangeCommentFilter}
          options={commentOptions}
          value={currentQuestionComment}
          className={styles.selectMargin}
          placeholder="Комментарий"
          selectablePlaceholder
          onReset={() => handleChangeCommentFilter(null)}
        />
      </div>
      {filteredQuestions.length > 0 ? (
        <>
          <div className={styles.questionContainer}>
            <Checkbox
              id={`question-select_all`}
              onChange={() => {
                toggleSelectAllQuestions(filteredQuestions);
              }}
              checked={isSelectAllActive}
              className={styles.checkbox}
              disabled={settingsIsDisabled}
            />
            <div
              className={`${
                settingsIsDisabled
                  ? styles.questionTextContainerDisabled
                  : styles.questionTextContainer
              }`}
              onClick={() => {
                if (!settingsIsDisabled) {
                  toggleSelectAllQuestions(filteredQuestions);
                }
              }}
            >
              {"Выбрать все"}
            </div>
          </div>
          {filteredQuestions.map((question: any) => (
            <div
              key={`question-${question[QUESTION_STRUCT.ID]}`}
              className={styles.questionContainer}
            >
              <Checkbox
                id={`checkbox-${question[QUESTION_STRUCT.ID]}`}
                onChange={() => {
                  toggleSelectedQuestion(question[QUESTION_STRUCT.ID]);
                }}
                checked={isQuestionSelected(question[QUESTION_STRUCT.ID])}
                className={styles.checkbox}
                disabled={settingsIsDisabled}
              />
              <div
                className={`${
                  settingsIsDisabled
                    ? styles.questionTextContainerDisabled
                    : styles.questionTextContainer
                }`}
                onClick={() => {
                  if (!settingsIsDisabled) {
                    toggleSelectedQuestion(question[QUESTION_STRUCT.ID]);
                  }
                }}
              >{`(${question[QUESTION_STRUCT.LINE]}-${
                question[QUESTION_STRUCT.CATEGORY]
              }) ${question[QUESTION_STRUCT.TITLE]}`}</div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.emptyText}>
          {"Нет вопросов, соответствующих выбранным фильтрам"}
        </div>
      )}
    </>
  );
};

export default branch(
  {
    questions: questionsSelector(),
  },
  TestQuestions
);
